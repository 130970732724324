import Link from "next/link";
import styles from "./Footer.module.scss";

export function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer__menu}>
        <nav className={styles.footer__menu__nav}>
          <img src='/images/logo_troc_actif.png' width='120'/>
        </nav>
      </div>
      <div className={styles.footer__menu}>
        <nav className={styles.footer__menu__nav}>
          <Link href="/about">
            <a className={styles.nav__link}>À propos</a>
          </Link>
          <Link href="/projet">
            <a target="_blank" className={styles.nav__link}>
              le projet troc actif
            </a>
          </Link>
          <Link href="https://franceactive-idf.org/">
            <a className={styles.nav__link}>
              le réseau france active en île-de-france
            </a>
          </Link>
          <Link href="/event">
            <a target="_blank" className={styles.nav__link}>
              événements
            </a>
          </Link>
        </nav>
      </div>

      <div className={styles.footer__menu}>
        <nav className={styles.footer__menu__nav}>
          <Link href="/faq">
            <a className={styles.nav__link}>FAQ</a>
          </Link>
          <Link href="/documents/cgu.pdf">
            <a target="_blank" className={styles.nav__link}>
              CGU
            </a>
          </Link>
          <Link href="/documents/Mentions_légales_Troc_Actif.pdf">
            <a target="_blank" className={styles.nav__link}>
              Mentions légales
            </a>
          </Link>
        </nav>
      </div>

      <div className={styles.footer__menu}>
        <div className={styles.footer__address}>
          <div className={styles.address__item}>
            12 rue de Vivienne, <br/>
            75002 Paris
          </div>
        </div>
        <div className={styles.footer__social}>
          <a
              className={styles.footer__social__button}
              href="https://twitter.com/franceactiveidf"
              target="_blanck"
          >
            <img
                className={styles.footer__social__button__icon}
                src="/images/twitter-logo.png"
            />
          </a>
          <a
              className={styles.footer__social__button}
              href="https://www.youtube.com/channel/UCQrMrmacikFz6NuYBozYmEg"
              target="_blanck"
          >
            <img
                className={styles.footer__social__button__icon}
                src="/images/youtube-logo.png"
            />
          </a>
          <a
              className={styles.footer__social__button}
              href="https://www.linkedin.com/company/france-active-ile-de-france/"
              target="_blanck"
          >
            <img
                className={styles.footer__social__button__icon}
                src="/images/linkedin-logo.png"
            />
          </a>
        </div>
      </div>
    </footer>
  );
}
