import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Alert } from "../components/alert/Alert";
import { Footer } from "../components/footer/Footer";
import { Header } from "../components/header/Header";
import styles from "../styles/MainTemplate.module.scss";
import cn from "classnames";

export function MainTemplate({
  isConnected = false,
  onAddOffer = () => {},
  children,
  headerShadow = true,
  alertMessage = "",
  alertError = false,
  onAlertClose = (): any => {},
}) {
  const [alert, setAlert]: [any, any] = useState();
  const router = useRouter();
  const [scrolledBlocked, setScrolledBlocked] = useState(false);

  useEffect(() => {
    if (router && router.query && router.query.tutorial) {
      setScrolledBlocked(true);
    } else {
      setScrolledBlocked(false);
    }
  }, [router]);

  useEffect(() => {
    if (alertMessage) {
      setAlert({
        message: alertMessage,
        isError: alertError,
      });
    } else {
      setAlert();
    }
  }, [alertError, alertMessage]);

  return (
    <div
      className={cn({
        [styles.container]: true,
        [styles.no__scroll]: scrolledBlocked,
      })}
    >
      <Alert
        visible={!!alert && !!alert.message}
        message={alert && alert.message}
        isError={alert && alert.isError}
        onClose={() => {
          onAlertClose();
          setAlert();
        }}
      />
      <Header
        boxShadow={headerShadow}
        isConnected={isConnected}
        onAddOffer={onAddOffer}
      />
      <div className={styles.content}>{children}</div>
      <Footer />
    </div>
  );
}
