import styles from "./Button.module.scss";
import cn from "classnames";
import Image from "next/image";

export function Button({
  text = "",
  boxShadow = false,
  theme = "primary",
  className = "",
  onClick = () => {},
  type = "button",
  disabled = false,
  loading = false,
  loadingText = "",
  icon = undefined,
}: {
  text?: string;
  boxShadow?: boolean;
  theme?:
    | "primary"
    | "secondary"
    | "blue"
    | "deepBlue"
    | "white"
    | "transparent__dark"
    | "transparent__light"
    | "gray"
    | "red";
  className?: string;
  onClick?: () => any;
  type?: "button" | "submit";
  disabled?: boolean;
  loading?: boolean;
  loadingText?: string;
  icon?: any;
}) {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled || loading}
      className={cn({
        [styles.button]: true,
        [styles.button__shadow]: boxShadow,
        [styles[`button__${theme}`]]: true,
        [className]: !!className,
      })}
    >
      {icon && (
        <div className={styles.icon__container}>
          <Image className={styles.icon} width={40} height={40} src={icon} />
        </div>
      )}
      <p className={styles.button__text}>{loading ? loadingText : text}</p>
    </button>
  );
}
