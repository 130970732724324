import styles from "./Header.module.scss";
import Link from "next/link";
import { Button } from "../button/Button";
import cn from "classnames";
import { useRouter } from "next/router";
import { useMobileMode, useOnDocumentClick, useUser } from "../../utils/hooks";
import { useEffect, useState } from "react";
import nookies from "nookies";
import config from "../../utils/config";

export function Header({
  theme = "dark",
  navItems = [
    {
      ref: "/projet",
      name: "Le projet Troc Actif",
    },
    {
      ref: "/about",
      name: "France Active en Île-de-France",
    },
    {
      ref: "/event",
      name: "Évènements",
    },
    {
      ref: "/faq",
      name: "FAQ",
    }
  ],
  isConnected = false,
  onAddOffer = () => {},
  boxShadow = true,
}: {
  theme?: "dark" | "light";
  navItems?: {
    ref: string;
    name: string;
  }[];
  isConnected?: boolean;
  onAddOffer?: any;
  boxShadow?: boolean;
}) {
  const router = useRouter();
  const [user, setUser] = useUser();
  const isMobile = useMobileMode();
  const [menuToggled, setMenuToggled] = useState(false);
  const [profileDropdownToggled, setProfileDropdownToggled] = useState(false);
  const [profileDropdown] = useState([
    {
      icon: "/images/company-icon.svg",
      label: "Ma fiche entreprise",
      action: () => {
        setProfileDropdownToggled(false);
        router.push("/account/company/profile");
      },
    },
    {
      icon: "/images/profile-icon.svg",
      label: "Mes contacts",
      action: () => {
        setProfileDropdownToggled(false);
        router.push("/account/profile");
      },
    },
    {
      icon: "/images/tag-icon.svg",
      label: "Gérer mes offres",
      action: () => {
        setProfileDropdownToggled(false);
        router.push("/account/offers");
      },
    },
    {
      icon: "/images/black-barter-icon.svg",
      label: "Mon compte Actif",
      action: () => {
        setProfileDropdownToggled(false);
        router.push("/account/barter");
      },
    },
    {
      icon: "/images/euro-icon.svg",
      label: "Facturation",
      action: () => {
        setProfileDropdownToggled(false);
        router.push("/account/billing");
      },
    },
    /*{
      icon: "/images/euro-icon.svg",
      label: "Facturation",
      action: () => {},
    },
    {
      icon: "/images/exchange-icon.svg",
      label: "Mes échanges en cours",
      action: () => {},
    },*/
    {
      icon: "/images/logout-icon.svg",
      label: "Se déconnecter",
      action: () => {
        setUser(undefined);
        nookies.destroy(null, "token");
        nookies.destroy(null, "refreshToken");
        localStorage.clear();
        router.replace("/");
      },
    },
  ]);

  useOnDocumentClick(() => {
    if (isMobile) {
      setMenuToggled(false);
    }
    setProfileDropdownToggled(false);
  });

  useEffect(() => {
    if (router && router.query && router.query.tutorial) {
      try {
        const tutorialStep = parseInt(router.query.tutorial as string);
        if (tutorialStep === 2 || tutorialStep === 3 || tutorialStep === 5 || tutorialStep === 9) {
          setProfileDropdownToggled(true);
        }
      } catch (error) {}
    }
  }, [router]);

  return (
    <>
      <header
        className={cn({
          [styles.header]: true,
          [styles.header__light]: theme === "light",
          [styles.box__shadow]: boxShadow,
        })}
      >
        <div
          onClick={() => {
            if (
              router.asPath.startsWith("/offer") ||
              router.asPath.startsWith("/account")
            ) {
              router.push("/offers");
            } else {
              router.push("/");
            }
          }}
          className={styles.logo__container}
        >
          <img
            src="/images/logo-white-sm.png"
            className={styles.logo}
            alt={config.brandName}
          />
          {/*<p className={styles.logo__label}>{config.brandName}</p>*/}
        </div>
        {!isMobile && !isConnected && (
          <nav className={styles.nav}>
            <ul className={styles.nav__list}>
              {navItems &&
                navItems.map((item, index) => (
                  <Link key={index} href={item.ref}>
                    <a
                      className={styles.nav__link}
                      style={{
                        fontWeight: router.asPath === item.ref ? 900 : 500,
                      }}
                    >
                      {item.name}
                    </a>
                  </Link>
                ))}
            </ul>
          </nav>
        )}
        {!isMobile && isConnected && (
          <div className={styles.offer__actions}>
            <Button
              text="Déposer une offre"
              className={styles.offer__action__button}
              theme="deepBlue"
              onClick={onAddOffer}
            />
            <Button
              text="Consulter les offres"
              className={styles.offer__action__button}
              onClick={() => router.push("/offers")}
              theme={
                theme === "dark" ? "transparent__dark" : "transparent__light"
              }
            />
          </div>
        )}
        {!isMobile && (
          <div
            className={cn({
              [styles.actions__container]: true,
              [styles.actions__container__light]: theme === "light",
            })}
          >
            {!isConnected && (
              <>
                <Button
                  text='Je me connecte'
                  className={styles.action__button}
                  theme={
                    theme === "dark" ? "white" : "transparent__light"
                  }
                  onClick={() => {
                    router.push("/login");
                  }}
                />
                <Button
                  text="Je m'inscris"
                  className={styles.action__button}
                  theme={theme === "dark" ? "deepBlue" : "primary"}
                  onClick={() => router.push("/signup")}
                />
              </>
            )}
            {isConnected && (
              <>
                <div className={styles.barter__balance__container}>
                  <p className={styles.barter__balance}>
                    Solde : {Intl.NumberFormat().format(user?.account?.balance)}
                  </p>
                  {/* TODO : impémenter une icone actif € */}
                  {/* <img
                    src="/images/barter-icon.png"
                    className={styles.barter__balance__icon}
                  /> */}
                </div>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setProfileDropdownToggled((oldVal) => !oldVal);
                  }}
                  className={styles.profile__button}
                >
                  <p className={styles.profile__name}>{user?.name}</p>
                  <img className={styles.profile__pic} src={user?.organization?.logo?.url ? user.organization.logo.url : '/images/nologo.png'}/>
                  {profileDropdownToggled && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className={styles.profile__dropdown}
                    >
                      <div className={styles.profile__dropdown__arrow}></div>
                      {profileDropdown.map((profileDropdownItem, index) => (
                        <div
                          key={index}
                          onClick={profileDropdownItem.action}
                          className={styles.profile__dropdown__item}
                        >
                          <div
                            className={
                              styles.profile__dropdown__item__icon__wrapper
                            }
                          >
                            <img
                              className={styles.profile__dropdown__item__icon}
                              src={profileDropdownItem.icon}
                            />
                          </div>
                          <p className={styles.profile__dropdown__item__label}>
                            {profileDropdownItem.label}
                          </p>
                        </div>
                      ))}
                    </div>
                  )}
                </button>
              </>
            )}
          </div>
        )}
        {isMobile && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              setMenuToggled(true);
            }}
            className={styles.menu__button}
          >
            <img
              className={styles.menu__button__icon}
              src="/images/menu-icon.svg"
            />
          </button>
        )}
        {isMobile && menuToggled && (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={styles.mobile__menu}
          >
            {!isConnected && (
              <nav className={styles.mobile__nav}>
                <ul className={styles.mobile__nav__list}>
                  {navItems &&
                    navItems.map((item, index) => (
                      <Link key={index} href={item.ref}>
                        <a
                          className={styles.mobile__nav__link}
                          style={{
                            fontWeight: router.asPath === item.ref ? 700 : 400,
                          }}
                        >
                          {item.name}
                        </a>
                      </Link>
                    ))}
                </ul>
              </nav>
            )}
            <div className={styles.mobile__actions__container}>
              {!isConnected && (
                <>
                  <Button
                    text="Se connecter"
                    className={styles.action__button}
                    theme="transparent__light"
                    onClick={() => {
                      router.push("/login");
                    }}
                  />
                  <Button
                    text="Je m'inscris gratuitement"
                    className={styles.action__button}
                    theme="blue"
                    onClick={() => router.push("/signup")
                    }
                  />
                </>
              )}
              {isConnected && (
                <>
                  <button className={styles.profile__button}>
                    <img className={styles.profile__pic} />
                    <div className={styles.profile__details}>
                      <p className={styles.profile__name}>{user?.name}</p>
                      <div className={styles.barter__balance__container}>
                        <p className={styles.barter__balance}>
                          Solde : {user?.account?.balance}
                        </p>
                        <img
                          src="/images/barter-icon.png"
                          className={styles.barter__balance__icon}
                        />
                      </div>
                    </div>
                  </button>
                  <div className={styles.offer__actions}>
                    <Button
                      text="Déposer une offre"
                      className={styles.offer__action__button}
                      theme="blue"
                      onClick={() => {
                        onAddOffer();
                        setMenuToggled(false);
                      }}
                    />
                    <Button
                      text="Consulter les offres"
                      className={styles.offer__action__button}
                      onClick={() => {
                        router.push("/offers");
                        setMenuToggled(false);
                      }}
                      theme="primary"
                    />
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className={styles.profile__dropdown}
                  >
                    <div className={styles.profile__dropdown__content}>
                      {profileDropdown.map((profileDropdownItem, index) => (
                        <div
                          key={index}
                          onClick={profileDropdownItem.action}
                          className={styles.profile__dropdown__item}
                        >
                          <div
                            className={
                              styles.profile__dropdown__item__icon__wrapper
                            }
                          >
                            <img
                              className={styles.profile__dropdown__item__icon}
                              src={profileDropdownItem.icon}
                            />
                          </div>
                          <p className={styles.profile__dropdown__item__label}>
                            {profileDropdownItem.label}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </header>
    </>
  );
}
