import { differenceInMinutes } from "date-fns";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { clientSideRequest } from "./api";
import nookies from "nookies";

export function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      if (typeof window !== "undefined") {
        const item = window.localStorage.getItem(key);
        if (item && (item.includes("{") || item.includes("["))) {
          return item ? JSON.parse(item) : initialValue;
        } else if (item === "true" || item === "false") {
          return item === "true";
        } else {
          return item ? item : initialValue;
        }
      }
      return initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  /**
   * Wrapped setValue to connect it to the LocalStorage
   * @param value
   */
  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (typeof valueToStore === "boolean") {
        window.localStorage.setItem(key, valueToStore ? "true" : "false");
      } else if (
        typeof window !== "undefined" &&
        typeof valueToStore === "object"
      ) {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      } else if (typeof value === "undefined") {
        window.localStorage.removeItem(key);
      } else {
        window.localStorage.setItem(key, valueToStore);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export function useResize() {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    const onResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  return {
    width,
    height,
  };
}

export function useMobileMode() {
  const { width } = useResize();
  return width < 800;
}

export function useTabletMode() {
  const { width } = useResize();
  return width < 1200;
}

export function useOnDocumentClick(listener = () => {}) {
  useEffect(() => {
    document.addEventListener("click", listener);
    return () => document.removeEventListener("click", listener);
  }, [listener]);
}

export function useOnScroll(listener = () => {}) {
  useEffect(() => {
    window.addEventListener("scroll", listener, { passive: true });
    return () => window.removeEventListener("scroll", listener);
  }, [listener]);
}

export function useTimeout(
  listener = () => {},
  duration = 1000,
  dependencies = []
) {
  useEffect(() => {
    const timeout = setTimeout(listener, duration);
    return () => clearTimeout(timeout);
  }, dependencies);
}

export function useInterval(
  listener = () => {},
  duration = 1000,
  dependencies = []
) {
  useEffect(() => {
    const interval = setInterval(listener, duration);
    return () => clearInterval(interval);
  }, dependencies);
}

export function useSectors() {
  const [sectors, setSectors] = useLocalStorage("sectors", []);

  useEffect(() => {
    (async () => {
      const retrievedSectors = await clientSideRequest(
        "/api/sectors/all",
        {},
        "GET"
      );
      setSectors(retrievedSectors);
    })();
  }, []);

  return [sectors, setSectors];
}

export function useSectorsLvlOne() {
  const [sectors, setSectors] = useLocalStorage("sectorsLvlOne", []);

  useEffect(() => {
    (async () => {
      const retrievedSectors = await clientSideRequest(
          "/api/sectors/by-depth/1",
          {},
          "GET"
      );
      setSectors(retrievedSectors);
    })();
  }, []);

  return [sectors, setSectors];
}

export function useSectorsLvlTwo() {
  const [sectors, setSectors] = useLocalStorage("sectorsLvlTwo", []);

  useEffect(() => {
    (async () => {
      const retrievedSectors = await clientSideRequest(
          "/api/sectors/by-depth/2",
          {},
          "GET"
      );
      setSectors(retrievedSectors);
    })();
  }, []);

  return [sectors, setSectors];
}

export function useSectorsLvlThree() {
  const [sectors, setSectors] = useLocalStorage("sectorsLvlThree", []);

  useEffect(() => {
    (async () => {
      const retrievedSectors = await clientSideRequest(
          "/api/sectors/by-depth/3",
          {},
          "GET"
      );
      setSectors(retrievedSectors);
    })();
  }, []);

  return [sectors, setSectors];
}

export function useUser() {
  const [user, setUser] = useLocalStorage("user", {});

  useEffect(() => {
    (async () => {
      const { token } = nookies.get();
      if (!!token) {
        const userDetails = await clientSideRequest("/api/user/details");
        setUser(userDetails);
      }
    })();
  }, []);

  return [user, setUser];
}

export function useContacts() {
  const [contacts, setContacts] = useLocalStorage("contacts", []);
  const [user] = useLocalStorage("user", undefined);

  useEffect(() => {
    (async () => {
      const { token } = nookies.get();
      if (!!token && !!user && !!user.organization) {
        const contacts = await clientSideRequest(
          `/api/contacts/organization/${user.organization.id}`
        );
        setContacts(contacts);
      }
    })();
  }, []);

  return [contacts, setContacts];
}

export function useNetworks() {
  const [networks, setNetworks] = useLocalStorage("networks", []);

  useEffect(() => {
    (async () => {
      const { token } = nookies.get();
      if (!!token && (!networks || networks.length === 0)) {
        const networks = await clientSideRequest(
            "/api/network/all",
            {},
            "GET"
        );
        setNetworks(networks);
      }
    })();
  }, []);

  return [networks, setNetworks];
}

export function useUnits() {
  const [units, setUnits] = useLocalStorage("units", []);

  useEffect(() => {
    (async () => {
      const { token } = nookies.get();
      if (!!token && (!units || units.length === 0)) {
        const fetchedUnits = await clientSideRequest("/api/unit/info");
        setUnits(fetchedUnits);
      }
    })();
  }, []);

  return [units, setUnits];
}

export function useUserPositions() {
  const [userPositions, setUserPositions] = useLocalStorage(
    "userPositions",
    []
  );

  useEffect(() => {
    (async () => {
      if (!userPositions || userPositions.length === 0) {
        const fetchedUnits = await clientSideRequest("/api/user_position/info");
        setUserPositions(fetchedUnits);
      }
    })();
  }, []);

  return [userPositions, setUserPositions];
}

export function useCompanySizes() {
  const [companySizes, setCompanySizes] = useLocalStorage("companySizes", []);

  useEffect(() => {
    (async () => {
      const { token } = nookies.get();
      if (!!token && (!companySizes || companySizes.length === 0)) {
        const fetchedUnits = await clientSideRequest("/api/employees/info");
        setCompanySizes(fetchedUnits);
      }
    })();
  }, []);

  return [companySizes, setCompanySizes];
}

export function useTurnovers() {
  const [turnovers, setTurnovers] = useLocalStorage("turnovers", []);

  useEffect(() => {
    (async () => {
      const { token } = nookies.get();
      if (!!token) {
        const fetchedTurnovers = await clientSideRequest("/api/turnover/info");
        setTurnovers(fetchedTurnovers);
      }
    })();
  }, []);

  return [turnovers, setTurnovers];
}

export function useReaches() {
  const [reaches, setReaches] = useLocalStorage("reaches", []);

  useEffect(() => {
    (async () => {
      const fetchedReaches = await clientSideRequest("/api/scope/info");
      const arrayReaches = [];
      for (let key in fetchedReaches) {
        arrayReaches.push(fetchedReaches[key]);
      }
      setReaches(arrayReaches);
    })();
  }, []);

  return [reaches, setReaches];
}

export function useActivitySector() {
  const [activitySector, setActivitySector] = useLocalStorage(
    "activitySector",
    []
  );

  useEffect(() => {
    (async () => {
      const { token } = nookies.get();
      if (!!token) {
        const fetchedactivitySector = await clientSideRequest("/api/activity_sector/info");
        setActivitySector(fetchedactivitySector);
      }
    })();
  }, []);

  return [activitySector, setActivitySector];
}

export function useLegalStatuses() {
  const [legalStatuses, setLegalStatuses] = useLocalStorage(
      "legalStatuses",
      []
  );

  useEffect(() => {
    (async () => {
      const fetchedStatuses = await clientSideRequest("/api/legal_status/info");
      const arrayStatuses = [];
      for (let key in fetchedStatuses) {
        arrayStatuses.push(fetchedStatuses[key]);
      }
      setLegalStatuses(arrayStatuses);
    })();
  }, []);

  return [legalStatuses, setLegalStatuses];
}

export function useCoopStatuses() {
  const [coopStatuses, setCoopStatuses] = useLocalStorage(
    'coopStatuses',
    []
  );

  useEffect(() => {
    (async () => {
      const fetchedCoopStatuses = await clientSideRequest("/api/coop_status/info");
      const arrayCoopStatuses = [];
      for (let key in fetchedCoopStatuses) {
        arrayCoopStatuses.push(fetchedCoopStatuses[key]);
      }
      setCoopStatuses(arrayCoopStatuses);
    })();
  }, []);

  return [coopStatuses, setCoopStatuses];
}

export function useApprovals() {
  const [approvals, setApprovals] = useLocalStorage(
    'approvals',
    []
  );

  useEffect(() => {
    (async () => {
      const fetchedApprovals = await clientSideRequest("/api/approval/info");
      const arrayApprovals = [];
      for (let key in fetchedApprovals) {
        arrayApprovals.push(fetchedApprovals[key]);
      }
      setApprovals(fetchedApprovals);
    })();
  }, []);

  return [approvals, setApprovals];
}

export function useSocialPurposes() {
  const [socialPurposes, setSocialPurposes] = useLocalStorage(
    'socialPurposes',
    []
  );

  useEffect(() => {
    (async () => {
      const fetchedSocialPurposes = await clientSideRequest("/api/social_purpose/info");
      const arraySocialPurposes = [];
      for (let key in fetchedSocialPurposes) {
        arraySocialPurposes.push(fetchedSocialPurposes[key]);
      }
      setSocialPurposes(fetchedSocialPurposes);
    })();
  }, []);

  return [socialPurposes, setSocialPurposes];
}

export function useDiscoveryModes() {
  const [discoveryModes, setDiscoveryModes] = useLocalStorage(
    'discoveryModes',
    []
  );

  useEffect(() => {
    (async () => {
      const fetchedDiscoveryModes = await clientSideRequest("/api/discovery_mode/info");
      const arrayDiscoveryModes = [];
      for (let key in fetchedDiscoveryModes) {
        arrayDiscoveryModes.push(fetchedDiscoveryModes[key]);
      }
      setDiscoveryModes(fetchedDiscoveryModes);
    })();
  }, []);

  return [discoveryModes, setDiscoveryModes];
}

export function useEmergencies() {
  const [emergencies, setEmergencies] = useLocalStorage("emergencies", []);

  useEffect(() => {
    (async () => {
      const fetchedEmergencies = await clientSideRequest("/api/emergency/info");
      const arrayEmergencies = [];
      for (let key in fetchedEmergencies) {
        arrayEmergencies.push(fetchedEmergencies[key]);
      }
      setEmergencies(arrayEmergencies);
    })();
  }, []);

  return [emergencies, setEmergencies];
}

export default function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);
  let observer = null;

  useEffect(() => {
    observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );
  }, []);

  useEffect(() => {
    if (observer !== null) {
      observer.observe(ref.current);
      // Remove the observer as soon as the component is unmounted
      return () => {
        observer.disconnect();
      };
    }
  }, [observer]);

  return isIntersecting;
}
