import nookies from "nookies";
import { differenceInMinutes } from "date-fns";
import NodeFormData from "form-data";
import axios, { AxiosError, ResponseType } from "axios";
import qs from "qs";
import config from "./config";

/**
 * Server side request
 * @param endpoint
 * @param method
 * @param params
 * @param headers
 * @param type
 * @returns
 */
export async function makeRequest(
  endpoint,
  ctx,
  method: "GET" | "POST" | "DELETE" | "PUT" = "GET",
  data = undefined,
  query = undefined,
  headers = {},
  type: ResponseType = "json"
) {
  try {
    const axiosInstance = axios.create({
      baseURL: config.serverUrl,
    });
    const { token } = nookies.get(ctx);
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    if (data instanceof NodeFormData) {
      headers = {
        ...headers,
        ...data.getHeaders(),
      };
    }
    const response = await axiosInstance({
      url: endpoint,
      method,
      headers,
      params: query,
      data,
      responseType: type,
    });
    return response.data;
  } catch (error) {
    const fullError = error as AxiosError;
    if (fullError.response) {
      console.log(fullError.response.status);
      throw fullError.response.data;
    }
  }
}

export async function clientSideRequest(
  endpoint,
  params = undefined,
  method: "GET" | "POST" | "DELETE" | "PUT" = "POST",
  query = undefined,
  type: ResponseType = "json"
) {
  try {
    const axiosInstance = axios.create({
      baseURL: "",
    });
    const response = await axiosInstance({
      url: endpoint,
      method,
      headers: {
        "Content-Type": "application/json",
      },
      data: params && method !== "GET" ? params : undefined,
      responseType: type,
      params: query,
    });
    return response.data;
  } catch (error) {
    const fullError = error as AxiosError;
    if (fullError.response) {
      console.log(fullError.response);
      console.log(fullError.response.status);
      if (fullError.response.status === 401) {
        throw {
          redirect: true,
          ...fullError.response.data,
        };
      } else {
        throw fullError.response.data;
      }
    }
  }
}

export async function clientSideMultipartPostRequest(
  endpoint,
  files,
  params,
  filesFieldName = "photo",
  type = "json"
) {
  const formData = new FormData();
  for (let file of files) {
    formData.append(filesFieldName, file);
  }
  for (let key in params) {
    formData.append(key, params[key]);
  }
  const apiRes = await fetch(endpoint, {
    method: "POST",
    headers: {},
    body: formData,
  });
  if (apiRes.ok) {
    const result = await (async () => {
      switch (type) {
        case "blob":
          return await apiRes.blob();
        case "json":
          return await apiRes.json();
        default:
          return await apiRes.text();
      }
    })();
    return result;
  } else if (apiRes.status === 401) {
    throw {
      redirect: true,
      ...(await apiRes.json()),
    };
  } else {
    throw await apiRes.json();
  }
}

export async function makeMultipartPostRequest(endpoint, file, params) {
  const formData = new FormData();
  formData.append("picture", file);
  formData.append("json", JSON.stringify(params));

  const apiRes = await fetch(`${config.serverUrl}${endpoint}`, {
    method: "POST",
    body: formData,
  });
  if (apiRes.ok) {
    return apiRes.json();
  } else {
    throw await apiRes.json();
  }
}

export async function getOffer(id, ctx) {
  return makeRequest(`/api/offers/show/${id}`, ctx);
}

export async function getOrganizationOffers(id, ctx) {
  return makeRequest(`/api/offers/organisation/${id}`, ctx, "GET");
}

export async function updateOrganization(params: NodeFormData, ctx) {
  return makeRequest(`/api/organization/edit`, ctx, "POST", params);
}

export async function sendNeed(
  params: {
    description: string;
    emergency: number;
  },
  ctx
) {
  return makeRequest(`/api/need/create`, ctx, "POST", params);
}

export async function getTransactions(ctx) {
  return makeRequest("/api/transaction/show", ctx);
}

export async function getExchanges(ctx) {
  return makeRequest("/api/mes-echanges", ctx);
}

export async function getLastOffers(ctx) {
  return makeRequest("/api/offers/last", ctx);
}

export async function getOfferSelection(ctx) {
  return makeRequest("/api/offers/selection", ctx);
}

export async function getOfferProposals(ctx) {
  return makeRequest("/api/offers/proposals", ctx);
}

export async function offerContact(
  id,
  params: {
    buyerFirstComment: string;
  },
  ctx
) {
  return makeRequest(`/api/trade/${id}/create`, ctx, "POST", params);
}

export async function offerPurchase(id, ctx) {
  return makeRequest(`/api/trade/${id}/create-shop`, ctx, "POST");
}

export async function signUp(params: {
  organization: string;
  siret: string;
  firstname: string;
  lastname: string;
  address_one_liner: string;
  legalStatus: string;
  position: string;
  phone: string;
  email: string;
  password: string;
  newsletter: string;
  terms: string;
}) {
  const result = await makeRequest("/api/register", null, "POST", params);
  console.log(result);
  return result;
}

export async function logIn(params: { email: string; password: string }) {
  const { token, refreshToken } = await makeRequest(
    "/api/auth/login",
    null,
    "POST",
    params
  );
  return { token, refreshToken };
}

export async function refreshToken(params: { refreshToken: any }) {
  return makeRequest("/api/auth/refresh", null, "POST", params);
}

export async function createOffer(
  params: NodeFormData /*{
    title: string;
    price?: number;
    sector: string;
    unit?: any;
    scope?: any;
    creditRatio: number; // 0 to 1
    dateStart: string; // dd-mm-yyyy
    dateEnd?: string; // dd-mm-yyyy
    photo?: any;
    description?: string;
    offerType: "TYPE_MAIN_OFFER" | "TYPE_UNUSED_ASSET";
  }*/,
  ctx
) {
  return makeRequest("/api/offers/create", ctx, "POST", params);
}

export async function updateOffer(
  id,
  params: NodeFormData /*{
    title: string;
    price?: number;
    sector: string;
    unit?: any;
    scope?: any;
    creditRatio: number; // 0 to 1
    dateStart: string; // dd-mm-yyyy
    dateEnd?: string; // dd-mm-yyyy
    photo?: any;
    description?: string;
    offerType: "TYPE_MAIN_OFFER" | "TYPE_UNUSED_ASSET";
  }*/,
  ctx
) {
  return makeRequest(`/api/offers/edit/${id}`, ctx, "POST", params);
}

export async function deleteOffer(id, ctx) {
  return makeRequest(
    `/api/offers/delete/${id}`,
    ctx,
    "DELETE",
    {},
    undefined,
    {},
    "text"
  );
}

export async function addContact(
  params: {
    firstname: string;
    lastname: string;
    position: string;
    phone: string;
    email: string;
    password: string;
  },
  ctx
) {
  return makeRequest("/api/contacts/add", ctx, "POST", params);
}

export async function updateContact(
    id,
    params: NodeFormData,
    ctx
) {
  return makeRequest(`/api/contacts/edit/${id}`, ctx, "POST", params);
}

export async function updateIdentifiers(
    id,
    params: {
      email: string,
      password: string,
    }
) {
  return await makeRequest(
      `/api/contacts/change-identifiers/${id}`,
      null,
      "POST",
      params);
}

export async function deleteContact(id, ctx) {
  return makeRequest(
    `/api/contacts/delete/${id}`,
    ctx,
    "DELETE",
    {},
    undefined,
    {},
    "text"
  );
}

export async function getContacts(id, ctx) {
  return makeRequest(`/api/contacts/organization/${id}`, ctx);
}

export async function getSectors(ctx) {
  return makeRequest("/api/sector/all", ctx);
}

export async function getNetworks(ctx) {
  return makeRequest("/api/network/all", ctx);
}

export async function getEvents(ctx) {
  return makeRequest("/api/event/all", ctx);
}

export async function getSectorsByDepth(depth, ctx) {
  return makeRequest(`/api/sector/show-by-depth/${depth}`, ctx);
}

export async function getUserDetails(ctx) {
  const { user, organization, offers, account } = await makeRequest(
    "/api/account-show",
    ctx
  );
  const offersArray = [];
  if (offers) {
    for (let id in offers) {
      offersArray.push(offers[id]);
    }
  }
  return {
    ...user,
    organization,
    offers: offersArray,
    account,
  };
}

export async function checkAuth(ctx, props = {}) {
  const cookies = nookies.get(ctx);
  const token = cookies.token;
  const refreshTokenVal = cookies.refreshToken;
  const lastTokenUpdate = cookies.lastTokenUpdate
    ? new Date(cookies.lastTokenUpdate)
    : undefined;
  const minutesSinceLastUpdate = differenceInMinutes(
    new Date(),
    lastTokenUpdate
  );

  let newToken;

  /**
   * If it's been more than 50 minutes since the last update of the token
   * we ask the server for a new token with the refreshToken
   */
  if (minutesSinceLastUpdate > 50) {
    const refTokRes = await refreshToken({
      refreshToken: refreshTokenVal,
    });
    newToken = refTokRes.token;
    nookies.set(ctx, "token", newToken, {
      path: "/",
    });
    nookies.set(ctx, "lastTokenUpdate", new Date().toISOString(), {
      path: "/",
    });
  }

  // If neither the token nor the new token is defined we redirect to login page
  if (!token && !newToken) {
    ctx.query.orginal_url = ctx.resolvedUrl;
    return {
      redirect: {
        destination: ctx && ctx.query ? `/login?${qs.stringify(ctx.query)}` : "/login",
        permanent: false,
      },
    };
  }

  return {
    props,
  };
}

export function getTokenFromCookies(req) {
  const cookies = nookies.get({ req });
  return cookies.token;
}

export function getTypeInfo(
  type:
    | "unit"
    | "user_position"
    | "trade_step"
    | "priority"
    | "emergency"
    | "status"
    | "scope"
    | "legal_status"
    | "match_flag"
    | "employees"
    | "turnover"
    | 'coop_status'
    | 'approval'
    | 'social_purpose'
    | 'discovery_mode',
  ctx
) {
  return makeRequest(`/api/info/${type}`, ctx);
}

export function searchOffers(
  page = 1,
  params: {
    q?: string;
    sector?: number | string;
    fullCredit?: boolean;
    network?: number | string;
    priceMin?: number;
    priceMax?: number;
    bookmark?: boolean;
    employees?: number | string;
    barterShop?: boolean;
  },
  ctx
) {
  return makeRequest("/api/recherche", ctx, "POST", params, {
    page,
  });
}

export function downloadBarterReportUrl(organizationId) {
  return `${config.serverUrl}/api/telecharger-releve-barter/${organizationId}`;
}

export async function requestPasswordReset(
  params: {
    email: string;
  },
  ctx
) {
  return makeRequest("/api/forgot-password", ctx, "POST", params);
}

export async function changePassword(
    email,
    key,
    params: {
      password: string;
    }) {

  const result = await makeRequest(
          `/api/change-password/${email}/${key}`,
          null,
          "POST",
          params);
      return result;
    }

export async function getInvoices(ctx) {
  return makeRequest("/api/billing/show", ctx, "GET");
}
